import React from "react";
import ReactDOM from "react-dom";
import { addLocaleData, IntlProvider } from "react-intl";
import { getLocale, getMessages, locales } from "../../messages";
import { SelectCourseStep } from "../../components/react/student-signup/student-signup";

(function init() {
    initCollapsible();
})();

function initCollapsible() {
    document.addEventListener("click", function(event) {
        // Check if one of the clicked elements is a .qna__heading
        if (
            event.target.matches(".qna__heading") ||
            event.target.closest(".qna__heading")
        ) {
            toggleCollapsible(event);
        }
    });
}

function toggleCollapsible(event) {
    const item = event.target.closest(".qna__item");
    const collapse = item.querySelector(".panel-collapse");

    collapse.classList.toggle("show");

    const icon = item.querySelector(".qna__question > i");
    if (icon) {
        icon.classList.toggle("rotated");
    }

    event.preventDefault();
    event.stopPropagation();
}

function selectCourse(node) {
    const locale = getLocale() || "nl";
    const messages = getMessages(locale);
    addLocaleData(locales);

    ReactDOM.render(
        <IntlProvider locale={locale} key={locale} messages={messages}>
            <SelectCourseStep {...node.dataset} />
        </IntlProvider>,
        node
    );
}

function showNextButton(nextButton) {
    const radioButtons = document.querySelectorAll('input[type="radio"]');

    if (!radioButtons.length) {
        return;
    }

    function toggleNextButton() {
        const isAnyChecked = Array.from(radioButtons).some(
            radio => radio.checked
        );
        nextButton.classList.toggle("hidden", !isAnyChecked);
    }

    toggleNextButton();
    radioButtons.forEach(radio => {
        radio.addEventListener("change", toggleNextButton);
    });
}

window.htmx?.onLoad(() => {
    const node = document.getElementById("react-select-course");
    if (node) {
        selectCourse(node);
    }

    const nextButton = document.getElementById("next");
    if (nextButton) {
        showNextButton(nextButton);
    }

    document.addEventListener("htmx:beforeRequest", function(event) {
        const id = event?.target?.id;
        if (!id) return;

        const container = document.querySelector(".student-signup");
        const content = document.querySelector(".student-signup__content");
        if (!container) return;
        if (id === "previous") {
            content.style.transform = "translateX(100%)";
            container.classList.add("reverse");

            setTimeout(() => {
                container.classList.remove("reverse");
            }, 500);
        } else if (id.includes("next")) {
            content.style.transform = "translateX(-100%)";
        }
    });
});
