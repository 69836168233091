import React, { useEffect, useState } from "react";
import { CourseConsumer } from "../../../data/course";
import { useAsync } from "react-use";
import { messages } from "./messages";
import { msg } from "../translations/components/Message";
import { CourseSelect } from "./CourseSelect";

const courseConsumer = new CourseConsumer();

const getSelectedCourses = selectedCourses => {
    if (!selectedCourses) {
        return [];
    }
    return JSON.parse(selectedCourses);
};

export function SelectCourseStep(props) {
    const [selectedCourses, setSelectedCourses] = useState(
        getSelectedCourses(props.selectedCourses)
    );
    const state = useAsync(async () => {
        return courseConsumer.list({ is_quick_choice: true });
    });
    const selectedIds = selectedCourses.map(course => course.id);
    const quickCourses = (state.value || []).filter(
        course => !selectedIds.includes(course.id)
    );

    useEffect(() => {
        const submitButton = document.getElementById("save-course");
        submitButton.disabled = !selectedCourses.length;
    }, [selectedCourses]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 36
            }}
        >
            <CourseSelect
                onSelect={course => {
                    if (course) {
                        setSelectedCourses([
                            ...selectedCourses,
                            {
                                id: course.value,
                                name: course.label,
                                icon: course.icon
                            }
                        ]);
                    }
                }}
                selectedCourses={selectedIds}
            />
            {!!quickCourses.length && (
                <div>
                    <p className={"signup-course__text"}>
                        {msg(messages.makeChoice)}
                    </p>
                    <p className="signup-course__text signup-course__text--small">
                        {msg(messages.chooseOneOrMore)}
                    </p>
                    <ul className={"signup-course__list"}>
                        {quickCourses.map(course => (
                            <CourseItem
                                key={course.id}
                                {...course}
                                onClick={() => {
                                    setSelectedCourses([
                                        ...selectedCourses,
                                        course
                                    ]);
                                }}
                            />
                        ))}
                    </ul>
                </div>
            )}
            {!!selectedCourses.length && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px"
                    }}
                >
                    <p className="signup-course__text signup-course__text--small">
                        {msg(messages.selectedCourses)}
                    </p>
                    <ul className={"signup-course__list"}>
                        {selectedCourses.map(course => (
                            <div key={course.id}>
                                <input
                                    style={{ display: "none" }}
                                    type="checkbox"
                                    name="courses"
                                    value={course.id}
                                    defaultChecked
                                />
                                <CourseItem
                                    edit
                                    key={course.id}
                                    {...course}
                                    onClick={() => {
                                        setSelectedCourses(
                                            selectedCourses.filter(
                                                c => c.id !== course.id
                                            )
                                        );
                                    }}
                                />
                            </div>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

function CourseItem({ name, icon, onClick, edit }) {
    return (
        <li className="signup-course__list-item" onClick={onClick}>
            {icon && <img src={icon} alt={`Icon for ${name} course`} />}
            {name}
            {edit && <i className={"fas fa-times"} />}
        </li>
    );
}
